// PropertyDetails.js

import React, { useState, useEffect, useContext, useCallback } from 'react';
import Map from './Map';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import './PropertyDetails.css';
import axiosInstance from '../../../axios/axiosConfig';
import AuthContext from '../../../context/AuthContext';
import '../../ChangeLanguage/i18next.js';
import { Helmet } from 'react-helmet';
import SocialShareButtons from './SocialShareButtons/SocialShareButtons';

function PropertyDetails() {
  const { id } = useParams();
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [property, setProperty] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const authContext = useContext(AuthContext);
  const [visiblePhotos, setVisiblePhotos] = useState(window.innerWidth <= 768 ? 2 : 3);

  const getDescriptionText = useCallback(() => {
    return property ? (i18n.language === 'en' ? property.description.EN : property.description.GR) : '';
  }, [property, i18n.language]);

  const [showFullDescription, setShowFullDescription] = useState(() => {
    const savedState = localStorage.getItem('showFullDescription');
    return savedState !== null ? JSON.parse(savedState) : false;
  });

  useEffect(() => {
    localStorage.setItem('showFullDescription', JSON.stringify(showFullDescription));
  }, [showFullDescription]);

  useEffect(() => {
    localStorage.setItem('visiblePhotos', visiblePhotos);
  }, [visiblePhotos]);

  function formatNumber(number) {
    if (number === null || number === undefined || isNaN(number)) {
      return '';
    }
    return number.toLocaleString('de-DE');
  }

  const goToNextImage = useCallback(() => {
    if (currentImageIndex < property.photos.length - 1) {
      setCurrentImageIndex(prev => prev + 1);
    }
  }, [currentImageIndex, property]);

  const goToPrevImage = useCallback(() => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(prev => prev - 1);
    }
  }, [currentImageIndex]);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await axiosInstance.get(`/api/properties/${id}`);
        setProperty(response.data);
      } catch (err) {
        console.error("Error fetching property data:", err);
        setProperty(null);
      }
    };
    fetchProperty();
  }, [id]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isModalOpen) {
        switch (event.key) {
          case 'ArrowRight':
            goToNextImage();
            break;
          case 'ArrowLeft':
            goToPrevImage();
            break;
          case 'Escape':
            closeModal();
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen, goToNextImage, goToPrevImage]);

  // useEffect(() => {
  //   if (property && property.photos && property.photos.length > 0) {
  //     document.querySelector('meta[property="og:image"]').setAttribute("content", property.photos[0]);
  //   }
  // }, [property]);

  const openModal = (index) => {
    setIsModalOpen(true);
    setCurrentImageIndex(index);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImageIndex(null);
  };

  const toggleDescription = () => setShowFullDescription(!showFullDescription);

  const togglePhotosVisibility = () => {
    setVisiblePhotos(prevVisiblePhotos => prevVisiblePhotos > (window.innerWidth <= 768 ? 2 : 3) ? (window.innerWidth <= 768 ? 2 : 3) : property.photos.length);
  };

  if (!property) {
    return <div>Loading...</div>;
  }

  let featureCounter = 0;
  if (property.attic) featureCounter++;
  if (property.screens) featureCounter++;
  if (property.storage) featureCounter++;
  if (property.elevator) featureCounter++;
  if (property.playroom) featureCounter++;
  if (property.fireplace) featureCounter++;
  if (property.furnished) featureCounter++;
  if (property.airConditioning) featureCounter++;
  if (property.solarWaterHeater) featureCounter++;
  if (property.underfloorHeating) featureCounter++;

  const descriptionText = getDescriptionText();

  const descriptionParagraphs = descriptionText.split('\n').map((paragraph, index) => (
    <React.Fragment key={index}>
      <p>{paragraph}</p>
    </React.Fragment>
  ));

  const truncateText = (text, maxLength = 250) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const descriptionLines = descriptionText.split('\n');
  const briefDescription = descriptionLines.slice(0, 3).map((line, index) => <p key={index}>{line}</p>);
  const fullDescription = descriptionParagraphs;

  return (
    <div className="property-details">
      <Helmet>
        {/* <meta name="description" content={i18n.language === 'en' ? property.description.EN : property.description.GR} /> */}
        <meta
          name="description"
          content={
            i18n.language === 'en'
              ? truncateText(property.description.EN)
              : truncateText(property.description.GR)
          }
        />
        <meta property="og:title" content={i18n.language === 'en' ? property.title.EN : property.title.GR} />
        {/* <meta property="og:description" content={i18n.language === 'en' ? property.description.EN : property.description.GR} /> */}
        <meta
          property="og:description"
          content={
            i18n.language === 'en'
              ? truncateText(property.description.EN)
              : truncateText(property.description.GR)
          }
        />
        <meta property="og:image" content="https://adraxestate.com/static/media/about-us.ce0e1f7fa65bc9399aba.jpg" />
        {/* <meta property="og:image" content={property.photos[0]} /> */}
        <meta property="og:image:width" content="938" />
        <meta property="og:image:height" content="400" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image:alt" content={property.title.EN} />
        <meta property="og:image:type" content="image/jpeg" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={property ? `Property: ${i18n.language === 'en' ? property.title.EN : property.title.GR}` : 'Default Title'} />
        <meta name="twitter:description" content={property ? (i18n.language === 'en' ? property.description.EN : 'Default Description') : 'Default Description'} />
        <meta name="twitter:image" content={property ? 'https://adraxestate.com/static/media/about-us.ce0e1f7fa65bc9399abf.jpg' : 'https://adraxestate.com/static/media/about-us.ce0e1f7fa65bc9399abf.jpg'} />
        <meta name="twitter:url" content={window.location.href} />

        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={`real estate, property, buy property, location, features, [City/Area Name], ${i18n.language === 'en' ? property.location.EN : property.location.GR}`} />
        <link rel="canonical" href={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Offer",
            "name": `Property: ${i18n.language === 'en' ? property.title.EN : property.title.GR}`,
            "image": property.photos[0],
            "url": window.location.href,
            "description": i18n.language === 'en' ? property.description.EN : property.description.GR,
            "price": property.price,
            "priceCurrency": "EUR",
            "availableAtOrFrom": {
              "@type": "Place",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": `${i18n.language === 'en' ? property.location.EN : property.location.GR}`,
                "addressLocality": "",
                "addressRegion": "",
                "postalCode": "",
                "addressCountry": ""
              }
            }
          })}
        </script>
      </Helmet>

      <div className="property-photos">
        {property.photos && property.photos.slice(0, visiblePhotos).map((photo, index) => (
          <img
            src={`${photo}`}
            alt={`Property ${property.name}`}
            key={index}
            onClick={() => openModal(index)}
          />
        ))}
      </div>

      <button onClick={togglePhotosVisibility}
        style={{ margin: '20px auto', padding: '10px 20px', backgroundColor: '#f8f9fa', color: '#333', border: '1px solid #ddd', cursor: 'pointer', outline: 'none' }}
        onMouseOver={e => e.target.style.backgroundColor = '#e9ecef'}
        onMouseOut={e => e.target.style.backgroundColor = '#f8f9fa'}
        className="view-more-photos">
        {visiblePhotos > (window.innerWidth <= 768 ? 2 : 3) ? t('Εμφάνιση Λιγότερων') : t('Εμφάνιση Περισσότερων')}
      </button>

      {isModalOpen && (
        <div className="image-modal">
          <span className="close" onClick={closeModal}>&times;</span>
          {currentImageIndex > 0 && <span className="prev" onClick={goToPrevImage}>&#10094;</span>}
          <img src={`${property.photos[currentImageIndex]}`} alt={`Property ${property.name}`} />
          {currentImageIndex < property.photos.length - 1 && <span className="next" onClick={goToNextImage}>&#10095;</span>}
        </div>
      )}

      {property.youtubeLink && (
        <div className="youtube-video-container">
          <iframe
            src={`https://www.youtube.com/embed/${property.youtubeLink.split('v=')[1]}`}
            allowFullScreen
            title="Embedded youtube"
          ></iframe>
        </div>
      )}

      <SocialShareButtons
        url={window.location.href}
        title={i18n.language === 'en' ? property.title.EN : property.title.GR}
        image={property.photos[0]}
      />
      <h2 id="detail-title">{i18n.language === 'en' ? property.title.EN : property.title.GR}</h2>
      <p className='price'>{t('Τιμή')}: {formatNumber(property.price)}</p>
      <p>{t('Τοποθεσία')}: {i18n.language === 'en' ? property.location.EN : property.location.GR}</p>

      {showFullDescription ? fullDescription : briefDescription}

      {descriptionLines.length > 3 && (
        <button onClick={toggleDescription}
          style={{ margin: '20px auto', padding: '10px 20px', backgroundColor: '#f8f9fa', color: '#333', border: '1px solid #ddd', cursor: 'pointer', outline: 'none' }}
          onMouseOver={e => e.target.style.backgroundColor = '#e9ecef'}
          onMouseOut={e => e.target.style.backgroundColor = '#f8f9fa'}
          className="view-more">
          {showFullDescription ? t('Εμφάνιση Λιγότερων') : t('Εμφάνιση Περισσότερων')}
        </button>
      )}

      <p className='status'>{t('Κατάσταση')}: {t(property.status)}</p>
      <p>{t('Τύπος ακινήτου')}: {i18n.language === 'en' ? property.type.EN : property.type.GR}</p>
      <p>{t('Τετραγωνικά Μέτρα')}: {property.sqMeters}
        {authContext.isAdmin && (
          <Link className="edit-btn" to={`/properties/${property._id}/edit`}>Επεξεργασία</Link>
        )}
      </p>

      <p>{t('Απόσταση από τη Θάλασσα')}: {property.distanceFromSea !== null ? `${property.distanceFromSea} m` : 'N/A'}</p>
      <p>{t('Σύστημα Θέρμανσης')}: {i18n.language === 'en' ? property.heatingSystem.EN : property.heatingSystem.GR}</p>
      <p>{t('Ενεργειακή Κλάση')}: {property.energyClass || 'N/A'}</p>
      <p>{t('Κωδικός Ακινήτου')}: {property.propertyCode ? property.propertyCode : 'N/A'}</p>
      <p>{t('Έτος Κατασκευής')}: {property.constructionYear !== null ? property.constructionYear : 'N/A'}</p>
      <p>{t('Υπνοδωμάτια')}: {property.bedrooms !== null ? property.bedrooms : 'N/A'}</p>
      <p>{t('Κουζίνες')}: {property.kitchens !== null ? property.kitchens : 'N/A'}</p>
      <p>{t('Σαλόνια')}: {property.livingRooms !== null ? property.livingRooms : 'N/A'}</p>
      <p>{t('Όροφος')}: {property.floor !== null ? property.floor : 'N/A'}</p>
      <p>{t('Μπάνια')}: {property.bathrooms !== null ? property.bathrooms : 'N/A'}</p>
      <p>WC: {property.wc !== null ? property.wc : 'N/A'}</p>
      {property.renovationStatus && <p>{t('Κατάσταση ακινήτου')}: {property.renovationStatus}</p>}
      {property.petsAllowed && <p>{t('Επιτρέπονται Κατοικίδια')}</p>}
      {featureCounter > 0 && <><h2>{t('Περιλαμβάνονται επίσης')}</h2></>}
      {property.solarWaterHeater && <p>{t('Ηλιακός Θερμοσίφωνας')}</p>}
      {property.underfloorHeating && <p>{t('Ενδοδαπέδια Θέρμανση')}</p>}
      {property.elevator && <p>{t('Ανελκυστήρας')}</p>}
      {property.airConditioning && <p>{t('Κλιματισμός')}</p>}
      {property.furnished && <p>{t('Έπιπλα')}</p>}
      {property.playroom && <p>{t('Playroom')}</p>}
      {property.storage && <p>{t('Αποθήκη')}</p>}
      {property.attic && <p>{t('Σοφίτα')}</p>}
      {property.screens && <p>{t('Σίτες')}</p>}
      {property.fireplace && <p>{t('Τζάκι')}</p>}

      <p style={{ paddingBottom: '3%' }}></p>

      {property.location.EN !== null ?
        <Map property={property} /> :
        "Φορτώνει το χάρτη..."
      }

      <p style={{ paddingBottom: '3%' }}></p>

    </div>
  );
}

export default PropertyDetails;